import React from 'react'

const Loading = () => {
  return (
    <p className='text-center' style={{ marginTop: '100px' }}>
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </p>
  )
}

export default Loading