import { lazy, Suspense  } from "react";
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './pages/RootLayout';
import Loading from "./navigation/Loading";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'

const HomePage = lazy(() => import('./pages/HomePage'));
const ServicesPage = lazy(() => import('./pages/ServicesPage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const CareerPage = lazy(() => import('./pages/CareerPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const VictoryVaultPage = lazy(() => import('./pages/VictoryVaultPage'));
const BlogPage = lazy(() => import('./pages/BlogPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));



const router = createBrowserRouter([
  {
    path: '/', element: <RootLayout />, errorElement: <ErrorPage />,
    children: [
      { path: '/', element: <Suspense fallback={<Loading />} ><HomePage /> </Suspense>},
      { path: 'services', element: <Suspense fallback={<Loading />} ><ServicesPage /></Suspense> },
      { path: 'career', element: <Suspense fallback={<Loading />} ><CareerPage /></Suspense> },
      { path: 'about', element: <Suspense fallback={<Loading />} ><AboutPage /></Suspense> },
      { path: 'blog', element: <Suspense fallback={<Loading />} ><BlogPage /></Suspense> },
      { path: 'victoryvault', element: <Suspense fallback={<Loading />} ><VictoryVaultPage /></Suspense> },
      { path: 'contact', element: <Suspense fallback={<Loading />} ><ContactPage /></Suspense> },
    ],
  }
]);
  
function App() {
  return  <RouterProvider router={router} />;
}

export default App;
