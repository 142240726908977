import React from 'react'
import { NavLink } from 'react-router-dom'
import PhenobleLogo  from '../assets/images/Phenoble2.png'
import classes from './MainNavigation.module.css'

const MainNavigation = () => {
  const handleNavbarToggler = (event) => {
    let divElement = document.getElementById("navbarTogglerDemo02")
    divElement.setAttribute("class", "navbar-collapse collapse")
}
  return (

    <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top ">
        <div className="container-fluid">
            <NavLink className="navbar-brand" to="/">
                <img src={PhenobleLogo} alt='header-logo' style={{width:"auto", height:"40px"}} />
            </NavLink>
            
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse " id="navbarTogglerDemo02">
                <ul className="navbar-nav me-auto pt-3 mb-1 mb-lg-0 justify-content-end">
                    <li className={classes.list}>
                        <NavLink onClick={handleNavbarToggler} aria-current="page" to="/" className={classes.aLink}>Home</NavLink>
                    </li>
                    <li className={classes.list}>
                        <NavLink onClick={handleNavbarToggler} to="/services" className={classes.aLink}>Services</NavLink>
                    </li>
                    <li className={classes.list}>
                        <NavLink onClick={handleNavbarToggler} to="/about" className={classes.aLink}>About</NavLink>
                    </li>
                    <li className={classes.list}>
                        <NavLink onClick={handleNavbarToggler} to="/career" className={classes.aLink}>Career</NavLink>
                    </li>
                    <li className={classes.list}>
                        <NavLink onClick={handleNavbarToggler} to="/blog" className={classes.aLink}>Blog</NavLink>
                    </li>
                    <li className={classes.list}>
                        <NavLink onClick={handleNavbarToggler} to="/victoryvault" className={classes.aLink}>Victory Vault</NavLink>
                    </li>
                    <li className={classes.list}>
                        <NavLink onClick={handleNavbarToggler} to="/contact" className={classes.aLink}>Contact</NavLink>
                    </li>

                </ul>
                
            </div>
        </div>
    </nav>
  )
}

export default MainNavigation